<template>
  <div v-if="showMsg" class="text-center">
    <div class="gotham-font text-h3">Welcome {{ userName }}</div>
    <!-- <div class="text-h5 mt-12">Before you get started, be sure you have the following items handy:</div> -->
    <!-- <div class="text-h6 mt-4 text-left" style="max-width: 420px; margin:auto">
      <div>1. The bill of sale from your Tesla Packet</div>
      <div>2. The address to ship your license plates to</div>
    </div> -->
    <!-- You have no previous registrations. Click "New" above to get started</h3> -->

    <div class="text-h4 text-center mt-6 mb-6" style="">Begin the Registration Process Today!</div>

  <!--   <v-flex xs12 sm8 offset-sm2 class="my-12">
      <v-card>
        <v-img src="@/assets/images/license_plate.png" aspect-ratio="2.75" class="py-8">
          <v-row class="gotham-font px-4 mt-md-4 pt-lg-6" style="height: 100%;">
            <v-col cols="12" md="4" class="pa-3 pb-12 pb-sm-12 pb-md-0" v-for="({ name, body }, idx) in steps" :key="idx">
              <div class="gotham-font text-h4 text-lg-h3 mb-lg-6 font-weight-bold mainBlack--text justify-center">
                {{ name }}
              </div>
              <div class="gotham-font text-lg-body text-center mainBlack--text">
                {{ body }}
              </div>
            </v-col>
          </v-row>
        </v-img>
      </v-card>
    </v-flex> -->
    <CreateRegistrationModal :welcomePage="true"></CreateRegistrationModal>
  </div>
</template>

<script>
export default {
  name: 'NoRegistrationsMsg',
  props: {
    showMsg: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CreateRegistrationModal: () => import('./CreateRegistrationModal.vue'),
  },
  data: () => ({
    steps: {
      one: {
        name: 'STEP 1',
        body: 'Create a New Registration by clicking the button below',
      },
      two: {
        name: 'STEP 2',
        body: 'Enter the address the plates will be shipped to and upload your bill of sale',
      },
      three: {
        name: 'STEP 3',
        body: 'Receive your new license plates in the mail',
      },
    },
  }),
  computed: {
    userName: function() {
      let name = this.$store.state.app.userData.firstName + ' ' + this.$store.state.app.userData.lastName;
      if (name == 'undefined undefined') {
        name = '';
      }
      return name;
    },
  },
};
</script>

<style scoped></style>
